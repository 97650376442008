import React, { useState } from "react";
import {
  FooterContainer,
  FooterLinkItems,
  FooterLinkR,
  FooterLinksContainer,
  FooterLinkTitle,
  FooterWrap,
  SeekrLogo,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SubscribeForm,
  SubscribeInput,
  SubscribeButton,
  Modal,
  ModalContent,
  CloseButton,
  PdfLink
} from "./FooterElements";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import seekrLogo from "../../images/seekrLogo.png";
import pdfLocalUrl from "../../images/Privacy terms and conditions.pdf";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const handleSubscribe = async () => {
    const response = await fetch("https://connect.mailerlite.com/api/subscribers", {
      method: "POST",
      headers: {
        Authorization: "Bearer YOUR_API_KEY",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        fields: {
          name: "",
          last_name: "",
        },
        groups: ["128896319777408828"],
      }),
    });

    const result = await response.json();
    if (response.ok) {
      setShowModal(true); // Show thank-you popup on success
    }
    setEmail(""); // Reset email input
  };

  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };

  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            {/* Existing Footer content */}
            <FooterLinkItems>
              <FooterLinkTitle>{t("Seekr")}</FooterLinkTitle>
              <FooterLinkR to="/aboutus">{t("aboutUs")}</FooterLinkR>
              <FooterLinkR to="/product">{t("product")}</FooterLinkR>
              <FooterLinkR to="/social">{t("social")}</FooterLinkR>
              <FooterLinkR to="/support">{t("Support")}</FooterLinkR>
              <FooterLinkR to="/resource">{t("Resource")}</FooterLinkR>
              {/* New Privacy Policy Link */}
              <PdfLink href={pdfLocalUrl} target="_blank">{t("Privacy Policy")}</PdfLink>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>{t("Contact Us")}</FooterLinkTitle>
              <FooterLinkR to="/">{t("Tel")}: +852 6700 4198</FooterLinkR>
              <FooterLinkR to="/">{t("Email")}: info@vidilabs.com.hk</FooterLinkR>
              <FooterLinkR to="/">{t("Address")}</FooterLinkR>
              <SocialIcons>
                <SocialIconLink href="https://www.linkedin.com/company/seekr-hk/" target="_blank" aria-label="LinkedIn">
                  <FaLinkedin />
                </SocialIconLink>
                <SocialIconLink href="https://www.instagram.com/seekr.hk/" target="_blank" aria-label="Instagram">
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink href="https://www.facebook.com/seekr.hongkong/" target="_blank" aria-label="Facebook">
                  <FaFacebook />
                </SocialIconLink>
              </SocialIcons>
            </FooterLinkItems>
          </FooterLinksContainer>

          <SocialMedia>
            <SocialMediaWrap>
              <SocialLogo to="/" onClick={toggleHome}>
                <SeekrLogo src={seekrLogo} />
              </SocialLogo>
              <WebsiteRights>{t("Seekr © 2024 All rights reserved.")}</WebsiteRights>

              {/* Email Subscription Section */}
              <SubscribeForm>
                <SubscribeInput
                  type="email"
                  placeholder={t("Enter your email")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <SubscribeButton onClick={handleSubscribe}>{t("Subscribe")}</SubscribeButton>
              </SubscribeForm>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>

      {/* Modal for thank-you popup */}
      {showModal && (
        <Modal>
          <ModalContent>
            <h2>{t("Thank you!")}</h2>
            <p>{t("You have successfully subscribed to Seekr's newsletter.")}</p>
            <CloseButton onClick={closeModal}>{t("Close")}</CloseButton>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default Footer;
